import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BiCaretRight, BiCaretLeft } from "react-icons/bi"

import Bio from "../components/bio"
import Category from "../components/category"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Sidebar from "../components/sidebar"
import MainWrapper from "../components/mainWrapper"
import Breadcrumb from "../components/breadcrumb"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  // GatsbyImage Component
  const image = getImage(post.frontmatter.hero)
  // const ogImage = `${data.site.siteMetadata.siteUrl}${post.frontmatter.hero.childImageSharp.original.src}`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="lg:flex lg:justify-between">
        <MainWrapper>
          <article itemScope itemType="http://schema.org/Article">
            <div className="bg-white rounded p-4 mb-4 md:p-8">
              <Breadcrumb
                title={post.frontmatter.category}
                link={`/category/${post.frontmatter.category}/`}
              />
              <header>
                <h1 itemProp="headline" className="font-bold md:text-xl my-8">
                  {post.frontmatter.title}
                </h1>
              </header>
              <GatsbyImage
                image={image}
                alt={post.frontmatter.title}
                itemProp="image"
              />
              <time
                dateTime={post.frontmatter.date}
                className="text-right text-sm text-gray-700 mt-4 md:mt-8 block font-bold"
              >
                {post.frontmatter.date}
              </time>
            </div>
            <details
              open
              className="mb-4 prose max-w-none bg-white rounded p-4 md:p-8"
            >
              <summary className="duration-300 font-bold text-sm marker:text-blue-400">
                目次
              </summary>
              <nav
                className="text-sm"
                dangerouslySetInnerHTML={{
                  __html: post.tableOfContents,
                }}
              />
            </details>
            <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
              className="prose max-w-none bg-white rounded px-4 py-8 md:p-8"
            />
            <nav className="block rounded">
              <ul className="flex flex-col lg:flex-row lg:flex-wrap justify-between">
                <li className="block lg:w-1/2 bg-gray-50 bduration-200 border-b lg:border-0">
                  {previous && (
                    <Link
                      to={previous.fields.slug}
                      rel="prev"
                      className="px-8 py-4 block"
                    >
                      <p className="mb-2 text-sm">
                        <BiCaretLeft className="inline-block w-5 h-5 text-blue-400" />{" "}
                        前の記事
                      </p>
                      {previous.frontmatter.title}
                    </Link>
                  )}
                </li>
                <li className="block lg:w-1/2 bg-gray-50 duration-200 text-right border-l">
                  {next && (
                    <Link
                      to={next.fields.slug}
                      rel="next"
                      className="px-8 py-4 block"
                    >
                      <p className="mb-2 text-sm">
                        次の記事{" "}
                        <BiCaretRight className="inline-block w-5 h-5 text-blue-400" />
                      </p>
                      {next.frontmatter.title}
                    </Link>
                  )}
                </li>
              </ul>
            </nav>
            <footer className="md:hidden">
              <Bio />
              <Category />
            </footer>
          </article>
        </MainWrapper>
        <section className="hidden md:block">
          <Sidebar />
        </section>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        category
        hero {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 1200
              height: 720
              formats: [AUTO, WEBP, AVIF]
              layout: CONSTRAINED
            )
            original {
              src
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
