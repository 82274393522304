import React from "react"
import Category from "./category"
import Bio from "./bio"

const Sidebar = () => {
  return (
    <aside>
      <div className="lg:w-[330px]">
        <Bio />
        <div className="hidden lg:block h-5 w-full"></div>
        <Category />
      </div>
    </aside>
  )
}

export default Sidebar
